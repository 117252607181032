<template>
  <div class="ma-4">
    <v-row>
      <v-col>
        <span class="font-weight-black blue--text subtitle-1">RETURN MANAGEMENT </span><span class="red--text">(Contoh)</span>
      </v-col>
    </v-row>
    <v-row>

      <v-col cols="12" sm="8" md="8">

        <v-hover v-slot="{hover }" open-delay="200">
          <v-card  class="color-gradient-red-3" :elevation="hover ? 16 : 2">
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">

                      <div class="mb-2 font-weight-bold">Return Performance</div>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-progress-circular
                              rotate="360"
                              size="100"
                              width="15"
                              color="teal"
                              value="50"
                          >
                            {{  50 }}
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                      <div class="mb-16 mt-2 caption">
                        <p>Perbandingan Sales dengan Retur(include dalam perjalanan)</p>
                      </div>

                      <v-divider class="mb-4"></v-divider>
                      <div class="mb-2 font-weight-bold">Return Success</div>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-progress-circular
                              rotate="360"
                              size="100"
                              width="15"
                              color="teal"
                              value="50"
                          >
                            {{  50 }}
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                      <div class="mb-16 mt-2 caption">
                        <p>Perbandingan Return dalam perjalanan dengan Retur diterima</p>
                      </div>


                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-list-item-title>
                  <div class="font-weight-bold">RETURN</div>
                </v-list-item-title>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Retur Diterima Hari Ini
                    </v-list-item-title>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item-subtitle class="gray--text">Bandung 50 paket</v-list-item-subtitle>
                    <v-list-item-subtitle class="gray--text">Lombok 50 paket</v-list-item-subtitle>
                    <v-list-item-subtitle class="gray--text">Malang 50 paket</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Retur Diterima Kemarin
                    </v-list-item-title>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item-subtitle class="gray--text">Bandung 50 paket</v-list-item-subtitle>
                    <v-list-item-subtitle class="gray--text">Lombok 50 paket</v-list-item-subtitle>
                    <v-list-item-subtitle class="gray--text">Malang 50 paket</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Return Dalam Perjalanan
                    </v-list-item-title>
                    <v-divider class="mb-2"></v-divider>
                    <v-list-item-subtitle class="gray--text">Bandung 50 paket</v-list-item-subtitle>
                    <v-list-item-subtitle class="gray--text">Lombok 50 paket</v-list-item-subtitle>
                    <v-list-item-subtitle class="gray--text">Malang 50 paket</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>


                <div class="mt-10 ml-2">
                  <v-btn color="primary">Menuju Halaman Retur
                    <v-icon
                      right
                      dark
                    >
                      mdi-walk
                    </v-icon>
                  </v-btn>
                </div>

              </v-col>
            </v-row>

          </v-card>
        </v-hover>

      </v-col>

    </v-row>
  </div>
</template>

<script>
export default {
  name: "Dashboard"
}
</script>

<style scoped>

</style>